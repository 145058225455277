import type { Country } from './envs'
import { browserEnvs } from './envs'

type CountryConfig = {
  basePath: string
  targetSystem: string
  support: {
    phone: string
    formattedPhone: string
    email: string
  }
  features: {
    contractLegalText: boolean
  }
  publicApi: {
    smartCharging: string
  }
  localStoragePrefix: string
  customerServicePage?: string
  currencySign: string
  enterpriseApp: {
    prodUrl: string
    stagingUrl: string
  }
}

/**
 * Get configuration that only depends on the country.
 * This configuration is available both in the client and server.
 * Do not include any hardcoded secrets here.
 */
const getCountryConfig = (country: Country): CountryConfig => {
  switch (country) {
    case 'SE':
      return {
        basePath: '/se/el',
        targetSystem: 'SE-MS',
        support: {
          phone: '+4620460000',
          formattedPhone: '020-46 00 00',
          email: 'support@fortum.com',
        },
        features: {
          contractLegalText: false,
        },
        publicApi: {
          smartCharging: 'https://smartladdning.fortum.se/api/smart-charging/supported-models',
        },
        localStoragePrefix: 'gw-se.',
        customerServicePage: '/kundservice', // for finland and norway, this will be added after the contentful entry is ready
        currencySign: 'kr',
        enterpriseApp: {
          prodUrl: 'https://online.fortum.se',
          stagingUrl: 'https://eo-fortumsweden-feat-frontend-app.azurewebsites.net',
        },
      }
    case 'FI':
      return {
        basePath: '/fi/sahkoa',
        targetSystem: 'FI-MS',
        support: {
          phone: '+35820019000',
          formattedPhone: '0200 19000',
          email: 'support@fortum.com',
        },
        features: {
          contractLegalText: false,
        },
        publicApi: {
          smartCharging: 'https://smart-charging-fi.linkapp.no/api/smart-charging/supported-models',
        },
        localStoragePrefix: 'gw-fi.',
        currencySign: '€',
        enterpriseApp: {
          prodUrl: 'https://online.fortum.fi',
          stagingUrl: 'https://eo-fortumfinland-feat-frontend-app.azurewebsites.net',
        },
      }
    case 'NO':
      return {
        basePath: '/no/strom',
        targetSystem: 'NO-MS',
        support: {
          phone: '+4721494949',
          formattedPhone: '21 49 49 49',
          email: 'support@fortum.com',
        },
        features: {
          contractLegalText: true,
        },
        publicApi: {
          smartCharging: 'https://minside.fortum.no/api/smart-charging/supported-models',
        },
        localStoragePrefix: 'gw-no.',
        currencySign: 'kr',
        enterpriseApp: {
          prodUrl: 'https://online.fortum.no',
          stagingUrl: 'https://eo-fortumstrom-feat-frontend-app.azurewebsites.net',
        },
      }
    default:
      return {
        basePath: '/se/el',
        targetSystem: 'SE-MS',
        support: {
          phone: '+4620460000',
          formattedPhone: '020-46 00 00',
          email: 'support@fortum.com',
        },
        features: {
          contractLegalText: false,
        },
        publicApi: {
          smartCharging: 'https://smartladdning.fortum.se/api/smart-charging/supported-models',
        },
        localStoragePrefix: 'gw-se.',
        customerServicePage: '/kundservice', // for finland and norway, this will be added after the contentful entry is ready
        currencySign: 'kr',
        enterpriseApp: {
          prodUrl: 'https://online.fortum.se',
          stagingUrl: 'https://eo-fortumsweden-feat-frontend-app.azurewebsites.net',
        },
      }
  }
}

/**
 * Configuration for the current country. Available in the client.
 */
export const countryConfig = getCountryConfig(browserEnvs.NEXT_PUBLIC_COUNTRY)
